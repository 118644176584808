.App {
  text-align: center;
  font-family: "PT Sans", sans-serif;
  margin-top: 5em;
}

.spinner-border {
  margin-top: 2em;
  color: #117a8b;
}

.username-input {
  width: 50%;
  border: 6px solid #eee;
  border-radius: 4px;
  font-family: "PT Sans", sans-serif;
  font-size: 1.5em;
  font-weight: 300;
  margin-top: 35px;
  display: table-cell;
  padding: 10px 10px;
  transition: border-color .3s ease;
}

.message {
  width: 50%;
  margin: auto;
  font-size: 1.25em;
}

.site-title {
  font-weight: bold;
  font-size: 6em;
}

.desc {
  font-size: 1.5rem;
  color: grey;
}

.bar {
  fill: #474c69;
}

.bar:hover {
  fill: #7a80a2;
}

svg {
  margin: auto;
  overflow: auto;
  display: block;
}

.tick {
  font-family: "PT Sans", sans-serif;
  font-size: 1em;
  font-weight: bold;
}

.App-header {
  background-color: #282c34;
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border: 1px solid blue;
}

footer {
  position: relative;
  margin-top: 20px;
  left: 0;
  bottom: 0;
  width: 100%;
  color: grey;
  text-align: center;
}

.footer-text {
  color: #2f5277;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .username-input {
    font-size: 1em;
    width: 65%;
  }

  .desc {
    font-size: 1.2em;
  }

  .site-title {
    font-size: 4em;
  }

  .message {
    font-size: 0.7em;
    width: 65%;
  }

}
